import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/home/niko/repo/manuscrape_nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/home/niko/repo/manuscrape_nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/home/niko/repo/manuscrape_nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/home/niko/repo/manuscrape_nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/home/niko/repo/manuscrape_nuxt/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/home/niko/repo/manuscrape_nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/home/niko/repo/manuscrape_nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/niko/repo/manuscrape_nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/home/niko/repo/manuscrape_nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_g1RyjnFCC2XCqS3NLpv7yngCZ5AzjpmT7UJr_dW3BbY from "/home/niko/repo/manuscrape_nuxt/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_JhH8M1KzF3pQyhcHsoNTBLd8tKGet6zo2zTBVDe7nK4 from "/home/niko/repo/manuscrape_nuxt/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo from "/home/niko/repo/manuscrape_nuxt/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg from "/home/niko/repo/manuscrape_nuxt/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/home/niko/repo/manuscrape_nuxt/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/home/niko/repo/manuscrape_nuxt/plugins/sentry.client.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  slideovers_g1RyjnFCC2XCqS3NLpv7yngCZ5AzjpmT7UJr_dW3BbY,
  modals_JhH8M1KzF3pQyhcHsoNTBLd8tKGet6zo2zTBVDe7nK4,
  colors_E7kSti5pGZ28QhUUurq6gGRU3l65WuXO_KJC3GQgzFo,
  plugin_client_99tyrJVxV3tJlUbnb2rNb2lrWZJ3yCVaG393DnED9Fg,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE
]