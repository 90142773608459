
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexSQN7kz8mf8Pb5jozWNK_45M_Q9TMbPNFLSI6r2zjQG9hsMeta } from "/home/niko/repo/manuscrape_nuxt/pages/index.vue?macro=true";
import { default as loginZyY4Dz6bs5iiz_45cz91Pgi9UULyQRvwFxCNsI4VRn6F4Meta } from "/home/niko/repo/manuscrape_nuxt/pages/login.vue?macro=true";
import { default as logoutN_45ixXlcYSp9wivIzI19wIy3hDvC1gkYcFMsDc9VA2ngMeta } from "/home/niko/repo/manuscrape_nuxt/pages/logout.vue?macro=true";
import { default as drafts7GgINaigffM7hT9rcjzRKCOoHhnif9JDLGRSWLKiFJYMeta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/drafts.vue?macro=true";
import { default as index7QlqdsrXgt_45mNw_X9TLC2PrmWFYAJchjN_45J3TJksb0kMeta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/index.vue?macro=true";
import { default as edit_45imageD4qIbVHFVmTNLACpzOylODR028GonpYZ4VBL6zJUJpkMeta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/observations/[observationId]/edit-image.vue?macro=true";
import { default as indexHGVEoyHSL6q4awOhZ13gO6WokuPD1QP1JtdU8BhcMLIMeta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/observations/[observationId]/index.vue?macro=true";
import { default as indexfNpj_tPrO7WMtux5ECOtsGp4PGojiUkPQAGfs_GCUKIMeta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/observations/index.vue?macro=true";
import { default as index9JHGcwuhw5xscwqtAh1kvQk6QO3YGsAwXlFQBi_45jN6cMeta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/index.vue?macro=true";
import { default as newLjXHSTeIl_mNUTUPohMVqmdmUYhQaGKoWbeWW2hUIYoMeta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/new.vue?macro=true";
import { default as index_iYpF_Qefyy_ksbfcfr1DuA0H3DuHOxB7xfjiMbtQF4Meta } from "/home/niko/repo/manuscrape_nuxt/pages/user/index.vue?macro=true";
import { default as newUkD3Vn9gIOT7mEv00cgCqzLjaPiZD6hKuXyDzprWcosMeta } from "/home/niko/repo/manuscrape_nuxt/pages/user/new.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/logout.vue")
  },
  {
    name: "projects-projectId-drafts",
    path: "/projects/:projectId()/drafts",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/drafts.vue")
  },
  {
    name: "projects-projectId",
    path: "/projects/:projectId()",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/index.vue")
  },
  {
    name: "projects-projectId-observations-observationId-edit-image",
    path: "/projects/:projectId()/observations/:observationId()/edit-image",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/observations/[observationId]/edit-image.vue")
  },
  {
    name: "projects-projectId-observations-observationId",
    path: "/projects/:projectId()/observations/:observationId()",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/observations/[observationId]/index.vue")
  },
  {
    name: "projects-projectId-observations",
    path: "/projects/:projectId()/observations",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/observations/index.vue")
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/index.vue")
  },
  {
    name: "projects-new",
    path: "/projects/new",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/new.vue")
  },
  {
    name: "user",
    path: "/user",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/user/index.vue")
  },
  {
    name: "user-new",
    path: "/user/new",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/user/new.vue")
  }
]